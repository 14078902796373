<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <v-card outlined>
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
          >
            {{ title }}
            <help-btn :new-version="true" /> <v-spacer></v-spacer>
          </v-card-title>
          <v-row class="ma-1 mb-md-4">
            <v-col cols="4" class="pb-0 pt-0">
              <mh-drop-down
                :items="cities"
                v-model="selectedCityId"
                :disabled="loading"
                label="город"
                :item-text="isAdmin ? 'name_admin' : 'name'"
                item-value="num"
                :hide-details="true"
                :multiple="false"
                item-group="name_head2"
              >
              </mh-drop-down>
            </v-col>
            <v-col cols="4" class="text-center pb-0 pt-0">
              <main-calendar
                :disabled="chart_loading"
                @calendarok="calendarHandler"
                :disabledThrough="true"
              ></main-calendar>
            </v-col>
            <v-col cols="4" class="text-right pb-0 pt-0">
              <v-btn
                color="primary"
                elevation="0"
                @click="getViews"
                v-if="chart_loading !== true"
                :disabled="!selectedCityId"
              >
                Построить
              </v-btn>
              <v-btn
                v-else
                color="grey lighten-4"
                elevation="0"
                @click="stopCalc"
              >
                Остановить
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            Отчет за период
          </v-card-title>
          <v-card-text>
            <v-col cols="8">
              <highcharts
                v-if="
                  chartOptions.series[0].data.length && visionType === 'graph'
                "
                :options="chartOptions"
              ></highcharts>
              <!--              <v-btn-->
              <!--                  v-if="visionType !== 'graph'"-->
              <!--                  @click="copyToClipboard('dt-table')"-->
              <!--              >Копировать в буфер</v-btn-->
              <!--              >-->
              <!--              <v-data-table-->
              <!--                  id="dt-table"-->
              <!--                  v-if="visionType !== 'graph'"-->
              <!--                  :headers="tableHeaders"-->
              <!--                  :items="tableItems()"-->
              <!--                  :hide-default-footer="true"-->
              <!--                  multi-sort-->
              <!--                  :search="tableSearch"-->
              <!--                  disable-pagination-->
              <!--              >-->
              <!--                <template v-slot:top>-->
              <!--                  <v-text-field-->
              <!--                      v-model="tableSearch"-->
              <!--                      label="Поиск по таблице"-->
              <!--                      class="mx-4"-->
              <!--                  ></v-text-field>-->
              <!--                </template>-->
              <!--              </v-data-table>-->
            </v-col>
            <!--            <v-row justify="center">-->
            <!--              <v-col justify="center"-->
            <!--              ><v-progress-circular-->
            <!--                  indeterminate-->
            <!--                  color="primary"-->
            <!--              ></v-progress-circular></v-col-->
            <!--              ></v-row>-->
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <v-col class="text-center">
            <svg
              v-if="!isAlternate"
              width="75"
              height="78"
              viewBox="0 0 75 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.6143 37.6417C29.4491 38.6469 30.9569 41.8034 29.9517 44.6583L19.4368 74.2125C18.4316 77.0674 15.2751 78.5551 12.4001 77.5499C9.56532 76.5446 8.07755 73.3882 9.06269 70.5333L19.5776 40.9791C20.5828 38.1443 23.7393 36.6565 26.6143 37.6417Z"
                fill="#DA3A10"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.5318 10.52C63.2259 11.9073 64.2713 15.2246 62.8841 17.9186L56.5109 30.2831C55.1236 32.9571 51.7661 34.0226 49.0921 32.6354C46.4182 31.2482 45.3727 27.9309 46.7399 25.2368L53.1131 12.8723C54.5004 10.1983 57.8579 9.15289 60.5318 10.52Z"
                fill="#990099"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.5224 0.628454C42.518 0.930028 44.7296 3.64419 44.4079 6.65992L39.1806 57.1433C38.8791 60.1389 36.1448 62.3505 33.1291 62.0288C30.1334 61.7272 27.942 59.0131 28.2436 56.0174L33.4708 5.51394C33.7724 2.51831 36.5067 0.326881 39.5224 0.628454Z"
                fill="#109619"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.7704 38.1243C24.3237 36.4958 27.7415 37.2397 29.37 39.7528L38.3569 53.585C39.9854 56.1383 39.2616 59.536 36.7284 61.1846C34.1751 62.8332 30.7573 62.0893 29.1087 59.5561L20.1419 45.724C18.5134 43.1907 19.2573 39.7729 21.7704 38.1243Z"
                fill="#FF9801"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.1255 1.27215C38.719 -0.23572 42.1167 0.628791 43.6447 3.24243L56.3912 25.0361C57.9192 27.6699 57.0346 31.0475 54.4209 32.5755C51.8073 34.1034 48.4096 33.2188 46.9017 30.6052L34.1552 8.79138C32.6473 6.15764 33.5118 2.78001 36.1255 1.27215Z"
                fill="#990099"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M54.4796 11.1435C56.7715 9.21339 60.2497 9.49485 62.1998 11.7868L72.9962 24.4931C74.9463 26.7851 74.6649 30.3034 72.3729 32.2536C70.0609 34.2038 66.5827 33.9223 64.6325 31.6102L53.8362 18.9039C51.9061 16.5919 52.1876 13.0936 54.4796 11.1435Z"
                fill="#0070C0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.23404 52.8408C5.86778 51.3932 9.2253 52.3181 10.7332 54.9518L19.0164 69.8093C20.484 72.4632 19.5391 75.8408 16.9054 77.3085C14.2716 78.7761 10.9141 77.8312 9.40624 75.1974L1.12303 60.3399C-0.324524 57.666 0.600302 54.3085 3.23404 52.8408Z"
                fill="#0070C0"
              />
            </svg>
            <div class="text-md-body-1 body-2 pt-2 mw-295 mr-auto ml-auto">
              Задайте параметры и постройте отчет, он будет отображаться тут
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=DevicesReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
  </v-container>
</template>
<style lang="scss">
.mw-295 {
  max-width: 295px;
}
</style>
<script>
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import MainCalendar from "@/components/MainCalendar.vue";
import retypeLangRu from "../services/retypeLangRu";
import retypeLangEn from "../services/retypeLangEn";
import MhDropDown from "@/components/MhTvDropDown.vue";
import HelpBtn from "@/components/HelpBtn.vue";
import uuidv4 from "uuid/v4";
import BaseLoader from "@/components/BaseLoader.vue";
import { mapActions } from "vuex";
import { CONSTS } from "../services/constants";

export default {
  name: "DevicesView",
  components: {
    BaseLoader,
    HelpBtn,
    highcharts: Chart,
    MainCalendar,
    MhDropDown,
  },
  props: {
    page: {
      type: String,
      default: "equipment",
    },
  },

  beforeDestroy() {},
  methods: {
    ...mapActions(["storeFilters", "storeCities", "storeTvs", "storePrograms"]),
    async saveFilters() {
      await this.storeFilters({
        selectCityId: this.selectedCityId,
        selectedTVId: this.selectTVId,
        selectProgrammId: this.storedFilters.selectProgrammId,
        selectedSourceId: this.storedFilters.selectedSourceId,
      });
    },
    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },
    filterAutoComplete(item, queryText, itemText) {
      queryText = queryText.toLocaleLowerCase();
      let queryTextRu = retypeLangRu(queryText);
      let queryTextEn = retypeLangEn(queryText);
      itemText = itemText.toLocaleLowerCase();
      return (
        itemText.indexOf(queryTextEn) > -1 || itemText.indexOf(queryTextRu) > -1
      );
    },
    stopCalc() {
      this.chart_loading = null;
      this.isStoped = true;
      this.axiosCancel();
    },
    calendarHandler(range) {
      this.range = range;
    },
    async getCities() {
      this.selectedCityId = null;
      let res = await axios.post("/params/cities", {
        source_id: 1,
        helper: "mh",
      });
      if (res.data) {
        this.cities = res.data;
      }
    },
    async getTvs() {
      //this.selectTVId = null;
      let res = await axios.post("/params/tvs", {
        source_id: 1,
        helper: "mh",
        city_id: this.selectedCityId,
      });
      if (res.data && Array.isArray(res.data)) {
        this.tvss = [...res.data];
        for (const item of this.tvss) {
          item.export_name = this.isAdmin
            ? item.ns1_name_admin
            : item.ns1_name_ru;
        }
        this.$emit("tvList", this.tvss);
        await this.storeTvs(this.tvss);
      }
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      alert("Данные скопированы");
    },
    graphTypeClick(isGraph) {
      this.visionType = isGraph ? "graph" : "table";
    },
    tableItems() {
      let items = [];
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;

        if (this.indicator === "AudienceDemo") {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.categories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);

            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
              y2: chart.series[1].data[i].y.toString().replace(/\./g, ","),
            });
          }
        } else {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.categories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);
            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
            });
          }
        }
      }
      return items;
    },
    async getViews() {
      this.isStoped = false;
      this.chart_loading = true;
      this.reportUuid = uuidv4();
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");

      const req = new FormData();

      req.append("geo_id", this.selectedCityId);
      req.set("uuid", this.reportUuid);

      req.append("startDate", moment(this.range.start).format("YYYY-MM-DD"));
      req.append("endDate", moment(this.range.end).format("YYYY-MM-DD"));

      req.append("startTime", moment(this.range.start).format("HH:mm:ss"));
      req.append("endTime", moment(this.range.end).format("HH:mm:ss"));
      const CancelToken = axios.CancelToken;
      let that = this;
      const res = await axios
        .post(
          this.page === "view-type" ? "devices/view-type" : "devices/equipment",
          req,
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              that.axiosCancel = c;
            }),
          }
        )
        .catch(function () {
          this.chart_loading = false;
          alert("Произошла непредвиденная ошибка");
          return;
        });
      if (!this.isStoped) {
        this.chart_loading = false;
        this.data = res.data;
      }
    },
  },
  watch: {
    async selectedCityId(val, oldval) {
      let parent = null;
      let selElements = [];
      if (val !== oldval && !this.init) {
        if (
          (this.page.type === "tv" || this.page.type === "compare-tv") &&
          Array.isArray(this.storedFilters.selectedTVId)
        ) {
          // this.selectTVId = null;
          selElements = this.tvss.filter((el) =>
            this.selectTVId.includes(el.id)
          );
          if (selElements) {
            parent = selElements.map((el) => {
              return el.parent;
            });
          } else {
            this.selectTVId = null;
          }
        } else {
          let filterEL = this.tvss.find((el) => el.id == this.selectTVId);
          if (filterEL) {
            parent = filterEL.parent;
          } else {
            this.selectTVId = null;
          }
        }
      }
      await this.getTvs();
      if (parent) {
        if (
          (this.page.type === "tv" || this.page.type === "compare-tv") &&
          Array.isArray(this.storedFilters.selectedTVId)
        ) {
          let selElementsNew = this.tvss.filter((el) =>
            parent.includes(el.parent)
          );
          if (selElementsNew) {
            this.selectTVId = selElementsNew.map((el) => {
              return el.id;
            });
          } else {
            this.selectTVId = null;
          }
        } else {
          let filterEl = this.tvss.find((el) => el.parent == parent);
          if (filterEl) {
            this.selectTVId = [filterEl.id];
          } else {
            this.selectTVId = null;
          }
        }
      }
      await this.saveFilters();
    },
  },
  async mounted() {
    if (!this.hasPriv) return this.$router.push("/");
    this.cities = this.$store.getters.getCitiesList;
    if (this.cities.length == 0) {
      await this.getCities();
    }
    this.selectedCityId = this.storedFilters.selectCityId;
    await this.getTvs();
    if (Array.isArray(this.storedFilters.selectedTVId)) {
      this.selectTVId = this.storedFilters.selectedTVId;
    } else {
      this.selectTVId = [this.storedFilters.selectedTVId];
    }
    this.init = false;
  },
  data: () => ({
    axiosCancel: null,
    tvss: [],
    init: true,
    selectTVId: null,
    selectedCityId: null,
    report: null,
    tableSearch: null,
    visionType: "graph",
    loading: false,
    cities: [],
    isStoped: false,
    range: null,
    filter_loading: false,
    data: {},
    indicator: "",
    chart_loading: null,
    pageData: {
      title: "По оборудованию",
      type: "devices-view",
      many_channel: false,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_views_devices
      ];
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    storedFilters() {
      return this.$store.getters.getFilters;
    },
    types() {
      if (this.page === "view-type") {
        return {
          index_wtype_id1: "Live",
          index_wtype_id2: "Archive",
          index_wtype_id3: "vod",
          index_wtype_id4: "просмотр сохраненного тв контента",
          index_wtype_id5: "просмотр файла",
        };
      }
      return {
        index_dtype_id1: "Web",
        index_dtype_id2: "Операторская приставка",
        index_dtype_id3: "Smart TV",
        index_dtype_id4: "Планшет",
        index_dtype_id5: "Телефон",
        index_dtype_id6: "Apple TV",
        index_dtype_id7: "Медиаплеер",
      };
    },
    title() {
      let title = "По оборудованию";
      if (this.page === "view-type") {
        title = "Live / Archive";
      }
      return title;
    },
    tableHeaders() {
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Передача",
          value: "title",
          visible: true,
        },
        {
          text: "Серия",
          value: "sub",
          visible: true,
        },
        {
          text: "Начало, дата",
          value: "beginDate",
          visible: true,
        },
        {
          text: "Начало, время",
          value: "beginTime",
          visible: true,
        },
        {
          text: "Окончание, дата",
          value: "endDate",
          visible: true,
        },
        {
          text: "Окончание, время",
          value: "endTime",
          visible: true,
        },
        {
          text: this.chartTittle,
          value: "y",
          visible: true,
        },
      ];
      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }
      return headers.filter((el) => {
        return el.visible;
      });
    },

    heightChart() {
      let h = 300;
      // if (this.report !== "hour") {
      //   h =
      //     this.data.charts &&
      //     this.data.charts[0] &&
      //     this.data.charts[0].categories.length;
      //   h = h * 40 + 25;
      //   if (this.indicator === "AudienceDemo") {
      //     h = h * 2;
      //   }
      //   h = h < 400 ? 400 : h;
      // }
      return h;
    },
    chartOptions() {
      let data = this.data ?? [];

      if (data.length === 0) {
        return {};
      }
      let dataKeys = Object.keys(data);
      let series = [
        {
          name: "Процент оборудования",
          data: [],
        },
      ];
      if (this.page === "view-type") {
        series[0].name = "Процент просмотров";
      }

      for (let i = 0; i < dataKeys.length; i++) {
        if (dataKeys[i] !== "sum") {
          series[0].data.push({
            name: this.types[dataKeys[i]],
            y: Math.round((data[dataKeys[i]] / data["sum"]) * 100, 2),
          });
        }
      }

      return {
        chart: {
          type: "pie",
          events: {},
          height: this.heightChart,
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          itemMarginTop: 10,
          itemMarginBottom: 10,
        },
        title: { text: "" },
        colors: [
          "#F44336",
          "#1976D2",
          "#ff9900",
          "#109618",
          "#990099",
          "#00BFA5",
          "#E91E63",
        ],
        subtitle: { text: "" },
        exporting: {
          sourceWidth: 1200,
          chartOptions: {
            chart: {
              events: {},
            },
          },
          // buttons: {
          //   contextButton: {
          //     menuItems: buttons,
          //   },
          // },
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: this.plotOpts,

        credits: { enabled: false },
        series: series,
      };
    },
    plotOpts() {
      return {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        pie: {
          showInLegend: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      };
    },
  },
};
</script>
